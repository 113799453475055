import React from "react";
import Layout from "../components/Shared/Layout/Layout";
import { SEO } from "../components/Shared/SEO/SEO";
import { styled } from "linaria/react";
import { useRoutes } from "../lib/routes";
import { CarGrid } from "../components/Home/CarGrid/CarGrid";

const ShowroomPage = () => {
  const routes = useRoutes();
  return (
    <Layout>
      <SEO title={routes.showroom.label} description="This is an index page" />
      <CarGrid />
    </Layout>
  );
};

export default ShowroomPage;
